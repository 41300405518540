import $ from "jquery";

export function init() {
	$(document).ready(function () {
		// Array to store chat messages
		var chatHistory = [];

		// Function to add a message to the chat interface
		function addMessage(role, content) {

			if(content|length) {
				// Replace newlines with <br> tags
				content = content.replace(/\n/g, '<br>');

				// If role is user, replace with "You" and if role is assistant replace with "ChatAI"
				role = role === 'user' ? 'You' : 'ChatAI';

				$('#chat').append('<div class="mb-2"><span class="font-bold">' + role + ': </span>' + content + '</div>');
			}
		}

		// Event handler for the "Ask" button click
		$('#ask-button').click(function () {
			var question = $('#question').val();

			// Check if the question is not empty
			if (question.trim() !== '') {
				// Clear the input field
				$('#question').val('');

				// Add the user's question to the chat history
				chatHistory.push({ role: 'user', content: question });

				// Update the chat interface with the full history
				$('#chat').html('');
				chatHistory.forEach(function (message) {
					addMessage(message.role, message.content);
				});

				//Get entry id from div with data-entry-id attribute
				var entryId = $('#chat').data('entry-id');

				if(entryId|length) {
					// Send the question to the server and receive the response
					$.ajax({
						type: 'GET',
						url: '/actions/codechecker/chatpdf/ask-question', // Replace with the actual URL of your controller
						data: {
							id: entryId, // Replace with the entry ID
							q: question,
							history: chatHistory // Send chat history to the server
						},
						success: function (response) {
							// Add the response to the chat history
							chatHistory.push({ role: 'assistant', content: response });

							// Update the chat interface with the full history
							$('#chat').html('');
							chatHistory.forEach(function (message) {
								addMessage(message.role, message.content);
							});
						},
						error: function () {
							// Handle errors if needed
							addMessage('ChatPDF', 'An error occurred.');
						}
					});
				} else {
					// Handle errors if needed
					addMessage('ChatPDF', 'An error occurred.');
					console.log('No PDF entry id found.');
				}
			}
		});

		// Event listener for the "keydown" event on the #question input field
		$('#question').on('keydown', function (event) {
			// Check if the Enter key was pressed
			if (event.keyCode === 13) {
				// Trigger a click event on the #ask-button button
				$('#ask-button').click();
			}
		});
	});
}
