import $ from "jquery";

export function init() {
	$(document).on('change', '.code-lightswitch input', function () {
		var isChecked = $(this).is(':checked');
		var countryId = $(this).attr('name');

		if (isChecked) {
			var url = '/actions/codechecker/default/add-country-to-user';
		} else {
			var url = '/actions/codechecker/default/remove-country-from-user';
		}

		console.log(url);
		
		$.ajax({
			url: url,
			type: 'GET',
			data: {
				'country': countryId
			},
			dataType: 'json',
			success: function (data) {
				var rowToMove = $('tr[data-code-id="' + countryId + '"]');
				if(isChecked) {
					var tableToMoveTo = $('#active-countries');
				} else {
					var tableToMoveTo = $('#inactive-countries');
				}

				tableToMoveTo.append(rowToMove.clone());
				rowToMove.remove();

			},
			error: function (request, error) {
				alert('Sorry something went wrong');
			}
		});
	});

	$(document).ready(function () {
		$("#countrySearch").on("keyup", function () {
			var value = $(this).val().toLowerCase();
			var hasVisibleLinks = false;

			$(".flex-wrap a").filter(function () {
				var match = $(this).attr("data-country-name").toLowerCase().indexOf(value) > -1;
				$(this).toggle(match);
				if (match) {
					hasVisibleLinks = true;
				}
			});

			if (hasVisibleLinks) {
				$("#noResults").addClass('hidden');
			} else {
				$("#noResults").removeClass('hidden');
			}
		});
	});

	//Hide any images which have an incorrect src
	$('img').on('error', function () {
		$(this).addClass('hidden');
	});
}